import { Modal, ModalOverlay, MenuItem, useDisclosure } from "@chakra-ui/react";
import { MdStore } from "react-icons/md";
import { useFetchCurrentUser } from "utils/currentUserHooks";
import SwitchAccountModalContent from "./Modal";

export default function SwitchAccountMenuItem() {
  const { data } = useFetchCurrentUser();
  const { isOpen, onToggle, onClose } = useDisclosure();
  if ((data?.brands?.totalCount || 0) < 2) return null;
  return (
    <>
      <MenuItem onClick={onToggle} icon={<MdStore />} fontWeight="inherit">
        Switch Account&hellip;
      </MenuItem>

      <Modal {...{ isOpen, onClose }}>
        <ModalOverlay />
        <SwitchAccountModalContent {...{ onClose }} />
      </Modal>
    </>
  );
}
