import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { CONTACT_PAGE } from "utils/config";

export default function ContactUsButton() {
  return (
    <Button
      as="a"
      variant="outline"
      colorScheme="lightPink"
      size="sm"
      mx={2}
      my={2}
      href={CONTACT_PAGE}
      target="_blank"
      rel="noopener noreferrer"
      rightIcon={<ExternalLinkIcon boxSize=".8em" />}
    >
      Contact Us
    </Button>
  );
}
