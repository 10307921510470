import { Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { APP_NAME, CONTACT_PAGE } from "utils/config";
import BrandName from "components/Shared/BrandName";
import LinkButton from "components/Shared/LinkButton";
import ErrorPage from "components/Shared/ErrorPage";

export default function InvalidSubscription() {
  return (
    <ErrorPage heading="Subscribe for Access" isWithNavBar>
      <Text fontSize="1.25em" my={10}>
        The {APP_NAME} subscription for <BrandName /> has ended. To continue
        using {APP_NAME}, please purchase a new subscription, which is available
        for purchase with or without the purchase of other RodeoCPG services.
      </Text>

      <LinkButton
        isExternal
        href={CONTACT_PAGE}
        colorScheme="pinkButton"
        whiteSpace="normal"
        rightIcon={<ExternalLinkIcon />}
      >
        Contact Us to Learn More
      </LinkButton>
    </ErrorPage>
  );
}
