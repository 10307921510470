import { DeleteIcon } from "@chakra-ui/icons";
import ConfirmMenuItem, {
  Props as ConfirmMenuItemProps,
} from "./ConfirmMenuItem";

type Props<TData = unknown, TVariables = unknown> = ConfirmMenuItemProps<
  TData,
  TVariables
>;

export default function DeleteConfirmMenuItem<TData, TVariables>({
  menuItemProps,
  ...rest
}: Props<TData, TVariables>) {
  return (
    <ConfirmMenuItem
      label="Delete"
      confirmButtonLabel="Delete"
      confirmText="Are you sure? You won’t be able to undo this action afterwards."
      menuItemProps={{ icon: <DeleteIcon />, ...menuItemProps }}
      {...rest}
    />
  );
}
