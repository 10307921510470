import { Box, Container, Text } from "@chakra-ui/react";
import { ReactElement, ReactNode, useContext } from "react";
import ErrorPage from "components/Shared/ErrorPage";
import { Module } from "__generated__/gql/graphql";
import { useCurrentBrandData } from "utils/currentUserHooks";
import ContactUsButton from "components/Shared/ContactUsButton";
import { RestrictedModulePathContext } from "utils/RestrictedModulePath";
import HomeButton from "components/Shared/HomeButton";

export const LABELS_BY_MODULE: Record<Module, string> = {
  [Module.ANALYTICS]: "Analytics",
  [Module.EMAIL_CAMPAIGNS]: "Email Campaigns",
  [Module.PROMOTIONS]: "Promotions",
  [Module.PURCHASE_ORDERS]: "Purchase Orders",
  [Module.SALES_PLANNER]: "Sales Planner",
};

type Props = {
  children: ReactNode;
};

export default function BrandModuleAccessGate({ children }: Props) {
  const brand = useCurrentBrandData();
  const restrictedModule = useContext(RestrictedModulePathContext);

  if (!restrictedModule) return children as ReactElement;

  const restrictedModuleLabel = LABELS_BY_MODULE[restrictedModule];

  return (
    <ErrorPage heading="Module Unavailable" isWithNavBar>
      <Container maxW="45ch">
        <Text fontSize="xl" fontWeight={500} mt={10}>
          The <b>{restrictedModuleLabel}</b> module is not enabled for{" "}
          <b>{brand?.name}</b>.
        </Text>

        <Text my={6}>
          Want to learn more?
          <ContactUsButton />
        </Text>

        <Box mt={10}>
          <HomeButton />
        </Box>
      </Container>
    </ErrorPage>
  );
}
