import { Box, Button } from "@chakra-ui/react";
import {
  MdWidgets,
  MdLocalShipping,
  MdStore,
  MdAreaChart,
  MdEmail,
  MdRequestQuote,
  MdAccountCircle,
  MdSettings,
  MdDiscount,
  MdComment,
  MdAnalytics,
} from "react-icons/md";
import { useLogout } from "utils/auth";
import {
  BRAND_SETTINGS,
  CUSTOMERS_INDEX,
  EMAIL_CAMPAIGNS_INDEX,
  PRODUCTS_INDEX,
  PURCHASE_ORDERS_INDEX,
  BRAND_RETAILERS_INDEX,
  USER_PROFILE,
  SALES_PLAN_INDEX,
  BRAND_PROMOTIONS_INDEX,
  BRAND_PROMOTIONS_ALL,
  PURCHASE_ORDERS_TABLE,
  PURCHASE_ORDERS_DASHBOARD,
  COMMENTS_INDEX,
  ANALYTICS_DATA_SOURCES,
  ANALYTICS_DASHBOARD,
  PRICING_CALCULATOR_INDEX,
  PRODUCTS_IMPORT,
  CUSTOMER_PRODUCT_PRESENCE,
} from "utils/links";
import asyncVoidHandler from "utils/asyncVoidHandler";
import { useBrandHasModule } from "utils/currentUserHooks";
import { Module } from "__generated__/gql/graphql";
import { PADDING_X } from "./constants";
import SideBarContainer, { SideBarContainerProps } from "./Container";
import SideBarSection from "./Section";
import SideBarLink from "./Link";
import SideBarSubLink from "./SubLink";

type SideBarProps = SideBarContainerProps;

export default function SideBar(props: SideBarProps) {
  const [logout, { loading: isLoggingOut }] = useLogout();

  const [
    isAnalyticsEnabled,
    isEmailCampaignsEnabled,
    isPromotionsEnabled,
    isPurchaseOrdersEnabled,
    isSalesPlannerEnabled,
  ] = useBrandHasModule(
    Module.ANALYTICS,
    Module.EMAIL_CAMPAIGNS,
    Module.PROMOTIONS,
    Module.PURCHASE_ORDERS,
    Module.SALES_PLANNER,
  );

  return (
    <SideBarContainer {...props}>
      <SideBarSection isFirst heading="My Brand">
        <SideBarLink
          href={PRODUCTS_INDEX}
          exactPathname={false}
          id="SideBar-products"
          IconComponent={MdWidgets}
          subLinks={
            <>
              <SideBarSubLink
                href={PRODUCTS_INDEX}
                hrefForCurrent={[PRODUCTS_INDEX, PRODUCTS_IMPORT]}
              >
                Products
              </SideBarSubLink>
              <SideBarSubLink
                href={PRICING_CALCULATOR_INDEX}
                exactPathname={false}
              >
                Pricing Calculator
              </SideBarSubLink>
            </>
          }
        >
          Products
        </SideBarLink>
        <SideBarLink
          href={CUSTOMERS_INDEX}
          exactPathname={false}
          IconComponent={MdLocalShipping}
          subLinks={
            <>
              <SideBarSubLink
                href={CUSTOMERS_INDEX}
                exactPathname={false}
                excludedPathnames={[CUSTOMER_PRODUCT_PRESENCE]}
              >
                All Customers
              </SideBarSubLink>
              <SideBarSubLink href={CUSTOMER_PRODUCT_PRESENCE}>
                Product Presence
              </SideBarSubLink>
            </>
          }
        >
          Customers
        </SideBarLink>
        <SideBarLink
          href={BRAND_RETAILERS_INDEX}
          exactPathname={false}
          IconComponent={MdStore}
        >
          Retailers
        </SideBarLink>
        {isPromotionsEnabled && (
          <SideBarLink
            href={BRAND_PROMOTIONS_INDEX}
            exactPathname={false}
            IconComponent={MdDiscount}
            subLinks={
              <>
                <SideBarSubLink href={BRAND_PROMOTIONS_INDEX}>
                  Dashboard
                </SideBarSubLink>
                <SideBarSubLink href={BRAND_PROMOTIONS_ALL}>
                  All Promotions
                </SideBarSubLink>
              </>
            }
          >
            Promotions
          </SideBarLink>
        )}
      </SideBarSection>

      {(isSalesPlannerEnabled ||
        isEmailCampaignsEnabled ||
        isPurchaseOrdersEnabled ||
        isAnalyticsEnabled) && (
        <SideBarSection heading="Tools">
          {isSalesPlannerEnabled && (
            <SideBarLink
              href={SALES_PLAN_INDEX}
              id="SideBar-sales-planner"
              exactPathname={false}
              IconComponent={MdAreaChart}
            >
              Sales Planner
            </SideBarLink>
          )}
          {isEmailCampaignsEnabled && (
            <SideBarLink
              href={EMAIL_CAMPAIGNS_INDEX}
              id="SideBar-pitchable"
              exactPathname={false}
              IconComponent={MdEmail}
            >
              Email Campaigns
            </SideBarLink>
          )}
          {isPurchaseOrdersEnabled && (
            <SideBarLink
              href={PURCHASE_ORDERS_INDEX}
              exactPathname={false}
              IconComponent={MdRequestQuote}
              subLinks={
                <>
                  <SideBarSubLink href={PURCHASE_ORDERS_DASHBOARD}>
                    Dashboard
                  </SideBarSubLink>
                  <SideBarSubLink href={PURCHASE_ORDERS_TABLE}>
                    All Purchase Orders
                  </SideBarSubLink>
                </>
              }
            >
              Purchase Order Tracker
            </SideBarLink>
          )}
          {isAnalyticsEnabled && (
            <SideBarLink
              href={ANALYTICS_DASHBOARD}
              exactPathname={false}
              IconComponent={MdAnalytics}
              shallow
              subLinks={
                <>
                  <SideBarSubLink href={ANALYTICS_DASHBOARD} shallow>
                    Dashboard
                  </SideBarSubLink>
                  <SideBarSubLink
                    href={ANALYTICS_DATA_SOURCES}
                    exactPathname={false}
                  >
                    Data Sources
                  </SideBarSubLink>
                </>
              }
            >
              Analytics
            </SideBarLink>
          )}
        </SideBarSection>
      )}

      <Box display={{ base: "block", md: "none" }} mt="auto" borderTopWidth={1}>
        <SideBarSection>
          <SideBarLink
            href={COMMENTS_INDEX}
            IconComponent={MdComment}
            mb={2}
            borderBottomWidth={1}
          >
            Comments
          </SideBarLink>

          <SideBarLink
            href={BRAND_SETTINGS}
            id="SideBar-account-settings"
            IconComponent={MdSettings}
          >
            Account Settings
          </SideBarLink>
          <SideBarLink
            href={USER_PROFILE}
            id="SideBar-user-profile"
            IconComponent={MdAccountCircle}
          >
            User Profile
          </SideBarLink>
        </SideBarSection>

        <Button
          onClick={asyncVoidHandler(logout)}
          size="xs"
          fontWeight={100}
          color="magenta.veryDark"
          mt="auto"
          pt={4}
          pb={1}
          px={PADDING_X}
          h="auto"
          borderRadius={0}
          disabled={isLoggingOut}
          isLoading={isLoggingOut}
          loadingText="Signing Out…"
          id="SideBar-sign-out"
          justifyContent="flex-start"
          variant="ghost"
        >
          Sign Out
        </Button>
      </Box>
    </SideBarContainer>
  );
}
